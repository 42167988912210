<template>
  <div
    ref="productListBar"
    class="product_list_bar"> 
    <div class="product_list_bar--wrapper">
      <div class="product_list_bar--container">
        <div
          v-if="isMobileDevice"
          class="filters__container filters__container--mobile">
          <div
            v-if="hasCategories"
            class="button__filter button__filter--mobile">
            <ActionIconSlot
              :tooltip="$t('filters.categories')"
              :show-label="true"
              type-class="-categories-desktop"
              :modal-name="ICON_NAMES.CATEGORIES"
              animation="right-slide">
              <Categories />
            </ActionIconSlot>
          </div>
          <div class="button__filter button__filter--mobile">
            <span
              v-if="countFilterFacet"
              class="button__filter--mobile__badge">
              {{ countFilterFacet }}
            </span>
            <ActionIconSlot
              :tooltip="$t('filters.sort_and_filter')"
              :show-label="true"
              type-class="-filter-desktop"
              :modal-name="ICON_NAMES.FILTER"
              animation="right-slide">
              <FiltersContainer
                v-if="statusFilter"
                @handle-filter="getProductsList" />
            </ActionIconSlot>
          </div>
        </div>
        <SwitchGrid />
      </div>
      <div
        v-if="isDesktopDevice"
        class="filters__container filters__container--desktop">
        <div
          v-if="hasCategories"
          class="button__filter button__filter--desktop">
          <ActionIconSlot
            :tooltip="$t('filters.categories')"
            :show-label="true"
            :is-row-label="true"
            icon="i-categories"
            type-class="-categories-desktop"
            :modal-name="ICON_NAMES.CATEGORIES"
            :showModalClose="false"
            :modalParams="categoriesModalParams"
            animation="left-slide"
            @iconClicked="handleOpenCategories"
            @closeModal="handleCloseCategories">
            <Categories />
          </ActionIconSlot>
        </div>
        <div class="button__filter button__filter--desktop">
          <span
            v-if="countFilterFacet"
            class="button__filter--desktop__badge">
            {{ countFilterFacet }}
          </span>
          <ActionIconSlot
            :tooltip="$t('filters.sort_and_filter')"
            :show-label="true"
            :is-row-label="true"
            icon="i-filter"
            type-class="-filter-desktop"
            :modal-name="ICON_NAMES.FILTER"
            animation="right-slide">
            <FiltersContainer
              v-if="statusFilter"
              @updateCountChecked="updateDeliveryFiltersCount"
              @handle-filter="getProductsList" />
          </ActionIconSlot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapAction } from 'CommonUtils/store/state.js';
  import { watch, onMounted, ref, computed, defineAsyncComponent } from 'vue';

  import { DELIVERY_PARAMS } from 'CommonUtils/schemas/facetDelivery';
  import useUrl from 'CommonComposables/useUrl.js';
  import useFacetFilterLogic from 'CommonComposables/useFacetFilterLogic.js';
  import useDelivery from 'CommonComposables/useDelivery.js';
  import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
  import SwitchGrid from 'Components/ProductList/SwitchGrid.vue';
  import ActionIconSlot from 'CommonComponents/Layout/Header/ActionIconSlot.vue';
  import ICON_NAMES from 'CommonUtils/iconNames.js';

  export default {
    components: {
      ActionIconSlot,
      SwitchGrid,
      Categories: defineAsyncComponent(() => import('Components/Categories/Categories.vue')),
      FiltersContainer: defineAsyncComponent(() => import('Components/Filters/FiltersContainer.vue'))
    },
    props: {
      scrolledTop: {
        type: Number,
        default: () =>  0,
        required: false,
      }
    },
    emits: ['handleModal', 'handleFilter'],
    setup(props, { emit }) {
      const productListBar = ref(null);
      const isMounted = ref(false);
      const facetDeliveryFilter = ref('');
      const categoriesModalParams = ref(null);
      const { updateUrlParams, getCurrentParam } = useUrl();
      const { getSplitFilter, existCurrentFilter } = useFacetFilterLogic();
      const { isDesktopDevice, isMobileDevice } = useNavigatorDetect();
      const { deliveryFiltersCount, updateDeliveryFiltersCount } = useDelivery({ emit });

      const addCurrentUrl = mapAction('filter', 'addCurrentUrl');
      const removeItemChecked = mapAction('filter', 'removeFilter');

      const setHasProductListBar = mapAction('page', 'setHasProductListBar');

      const { statusFilterFacet, statusFilter } = mapGetters('uiState', {
        statusFilterFacet: 'getStatusFilterFacet',
        statusFilter: 'getStatusFilter',
      });

      const { isLoadingCart, eciExpress, clickAndCar } = mapGetters('cart', {
        isLoadingCart: 'getLoadingStatus',
        eciExpress: 'getEciExpress',
        clickAndCar: 'getClicAndCar',
      });
      const { filters, menubar } = mapGetters('filter', {
        filters: 'getFilters',
        menubar: 'getMenuBar',
      });

      watch(isLoadingCart, () => {
        if (isLoadingCart) {
          normalizeUrl();
        }
      });

      // Esto deberia bastar para tener el recuento de los filtros activos. Sin embargo, debido a un bug
      // sin solucionar en como se gestionan los filtros activos, da resultados incorrectos. Por eso se mantiene
      // el modo en que se calculaban antes el count de los tipos de envio y los filtros por separado, y
      // y se suman.
      // const countFilterFacet = computed(() => {
      //   return filters.value?.length;
      // });

      const countFilterFacet = computed(() => {
        const activeFilters = filters.value.filter((filter) => {
          return filter.id.split('::')[0] !== 'delivery_time';
        }).length;
        return activeFilters + deliveryFiltersCount.value;
      });

      const hasCategories = computed(() => {
        return Boolean(menubar?.value?.find((item) => item.type === 'categories'));
      });

      const normalizeUrl = () => {
        const TYPES_FILTER = getSplitFilter();
        const currentFilter = existCurrentFilter(TYPES_FILTER);

        if (currentFilter) {
          checkIfGeneralCentreAreValid(currentFilter);
        }

        const hasEciExpressDefault = filters.value.some(
          (delivery) => delivery._fid === eciExpress.value.general_centre
        );
        const hasClicAndCarDefault = filters.value.some(
          (delivery) => delivery._fid === clickAndCar.value.general_centre
        );

        if (
          (eciExpress.value.general_centre === clickAndCar.value.general_centre &&
            getCurrentParam(DELIVERY_PARAMS.DELIVERY_KEY) === DELIVERY_PARAMS.COLLECT) ||
          getCurrentParam(DELIVERY_PARAMS.DELIVERY_KEY) === DELIVERY_PARAMS.DELIVERY
        ) {
          return updateUrlParams(getCurrentParam(DELIVERY_PARAMS.DELIVERY_KEY), DELIVERY_PARAMS.DELIVERY_KEY);
        }

        if (hasEciExpressDefault && eciExpress.value.time_slot && !hasClicAndCarDefault)
          updateUrlParams(DELIVERY_PARAMS.DELIVERY, DELIVERY_PARAMS.DELIVERY_KEY);
        if (!hasEciExpressDefault && hasClicAndCarDefault && clickAndCar.value.time_slot)
          updateUrlParams(DELIVERY_PARAMS.COLLECT, DELIVERY_PARAMS.DELIVERY_KEY);
        if (hasEciExpressDefault && eciExpress.value.time_slot && hasClicAndCarDefault && clickAndCar.value.time_slot)
          updateUrlParams(DELIVERY_PARAMS.DELIVERY_AND_COLLECT, DELIVERY_PARAMS.DELIVERY_KEY);
      };

      const checkIfGeneralCentreAreValid = (currentFilter) => {
        const splitMarker = '::';
        if (currentFilter && currentFilter.includes(splitMarker)) {
          const splitFilters = currentFilter.split(splitMarker)[1].split(',,');
          splitFilters.forEach((filter) => {
            if (
              filter.length === 4 &&
              filter !== eciExpress.value.general_centre &&
              filter !== clickAndCar.value.general_centre
            ) {
              removeFilter({ _fid: filter });
            }
          });
        }
      };

      const removeFilter = async (trail) => {
        const FILTER_SEPARATOR = '::';
        const SPLIT_SEPARATOR = ',,';
        const SLASH_SEPARATOR = '/';

        const SPLIT_URL = decodeURI(facetDeliveryFilter.value)
          .replace(trail._fid, '')
          .replace(FILTER_SEPARATOR + SPLIT_SEPARATOR, FILTER_SEPARATOR)
          .replace(SPLIT_SEPARATOR + SPLIT_SEPARATOR, SPLIT_SEPARATOR);

        facetDeliveryFilter.value = SPLIT_URL;

        const NORMALIZE_URL = SPLIT_URL.split(SLASH_SEPARATOR).filter((filter) => {
          if (filter.split(FILTER_SEPARATOR)[1] && filter.split(FILTER_SEPARATOR)[1].length > 0) return filter;
        });

        await removeItemChecked(trail);
        await addCurrentUrl(NORMALIZE_URL.join(SLASH_SEPARATOR));
        emit('handleFilter', {}, true);
      };

      const getProductsList = (position, isChecked) => {
        updateDeliveryFiltersCount();
        emit('handleFilter', position, isChecked);
      };

      const handleOpenCategories = () => {
        categoriesModalParams.value = getCategoriesModalParams();
        document.getElementById('burger-handler')?.classList.add('active');
      }

      const handleCloseCategories = () => {
        document.getElementById('burger-handler')?.classList.remove('active');
      }

      const getCategoriesModalParams = (() => {
        if(!isMounted.value || !productListBar.value) return null;
        const containerCoords = productListBar.value.getBoundingClientRect();
        const modalPosition = containerCoords.top + containerCoords.height;
        return {
          '--modal-offset': modalPosition + 'px',
        }
      });

      watch(() => props.scrolledTop, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          nextTick(() => categoriesModalParams.value = getCategoriesModalParams());
        }
      })

      onMounted(() => {
        isMounted.value = true;
        setHasProductListBar(true);
      });

      return {
        productListBar,
        ICON_NAMES,
        isDesktopDevice,
        isMobileDevice,
        isMounted,
        countFilterFacet,
        statusFilterFacet,
        statusFilter,
        handleOpenCategories,
        handleCloseCategories,
        getProductsList,
        categoriesModalParams,
        hasCategories,
        filters,
        deliveryFiltersCount,
        updateDeliveryFiltersCount,
      };
    },
  };
</script>
